import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { FaSave } from 'react-icons/fa';
import ProductListPage from './ProductListPage';
import './ResultsPage.scss';
import SearchBar from './SearchBar';
import { useAuth } from './AuthProvider';
import axios from 'axios';

const ResultsPage = ({ onAddToCart, onRemoveFromCart }) => {
  const location = useLocation();
  const { query, resultsProducts } = location.state || {};
  const { isAuthenticated, userId } = useAuth(); // Get authentication status
  const [showModal, setShowModal] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const modalRef = useRef();
  const base = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const handleSaveClick = () => {
    if (!isAuthenticated || !userId) {
      console.warn('User must be logged in to save searches');
      return; // Allow access to results but prevent saving
    }
    setSearchTitle(query);
    setShowModal(true);
  };

  const handleSaveSearch = async () => {
    const newSearch = { 
      query, 
      searchResults: resultsProducts,
      title: searchTitle
    };

    try {
      const response = await axios.post(`${base}/api/saved-searches/save/${userId}`, newSearch);
      console.log('Search save response:', response.data);
      window.dispatchEvent(new Event('savedSearchUpdated'));
      setShowModal(false);
    } catch (error) {
      console.error('Failed to save search', error.response || error);
    }
  };

  const handleRemoveFromCart = async (productId) => {
    try {
      await axios.delete(`${base}/api/cart/removeItem`, {
        data: { userId, productId }
      });
      // Update the resultsProducts state to reflect the change
      resultsProducts = resultsProducts.filter(product => product.id !== productId); // Ensure you update the state correctly
    } catch (error) {
      console.error('Error removing product from cart:', error);
    }
  };

  console.log('Results Products:', resultsProducts); // Log the resultsProducts

  return (
    <div className="results-page">
      <div className="sticky-search-container">
        <div className="search-bar-wrapper">
          <SearchBar initialQuery={query} />
          <button 
            className="save-search-button" 
            onClick={handleSaveClick} 
            disabled={!isAuthenticated}
          >
            <FaSave /> Save
          </button>
        </div>
      </div>
      
      <ProductListPage 
        products={resultsProducts.map(product => ({ ...product, product_id: product.id }))} 
        onAddToCart={onAddToCart} // Pass down the add to cart function
        onRemoveFromCart={handleRemoveFromCart} // Pass down the remove from cart function
      />

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content" ref={modalRef}>
            <h2>Save Search</h2>
            <input
              type="text"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
              className="search-title-input"
            />
            <div className="modal-actions">
              <button onClick={() => setShowModal(false)}>Cancel</button>
              <button onClick={handleSaveSearch}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultsPage;



import React, { useState, useEffect } from 'react';
import TopIcons from './TopIcons';
import axios from 'axios';
import './DiscoverPage.scss';

const DiscoverPage = () => {
  const [posts, setPosts] = useState([]);
  const [activeTab, setActiveTab] = useState('discover');
  const base = process.env.REACT_APP_API_BASE_URL;
  const local = process.env.REACT_APP_LOCAL_URL;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${base}/api/discover/posts`);
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, [base]);

  return (
    <div className="discover-page">
      <TopIcons />
      <div className="content-wrapper">
        <div className="tabs">
          <button 
            className={`tab ${activeTab === 'discover' ? 'active' : ''}`}
            onClick={() => setActiveTab('discover')}
          >
            Discover
          </button>
          <button 
            className={`tab ${activeTab === 'following' ? 'active' : ''}`}
            onClick={() => setActiveTab('following')}
          >
            Following
          </button>
        </div>
        <div className="posts-grid">
          {posts.map(post => (
            <div key={post.id} className="post-card">
              {post.media && post.media.length > 0 && (
                post.media[0].media_type === 'image' ? (
                  <img 
                    src={post.media[0].signedUrl} 
                    alt={`Post ${post.id}`} 
                    className="post-image"
                  />
                ) : (
                  <video 
                    src={post.media[0].signedUrl} 
                    controls 
                    className="post-image"
                  />
                )
              )}
              <div className="post-overlay">
                {post.content && (
                  <p className="post-caption">{post.content}</p>
                )}
                <div className="post-info">
                  <span className="post-username">@{post.username}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DiscoverPage;

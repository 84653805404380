import React, { useState, useEffect } from "react";
import './ProfilePage.scss';
import TopIcons from "./TopIcons";
import { useAuth } from './AuthProvider'; // Import useAuth
import { useNavigate, useParams } from 'react-router-dom'; // Import useNavigate and useParams
import axios from 'axios';
import { FiLogOut, FiSettings, FiEdit2, FiImage, FiTrash2 } from 'react-icons/fi'; // Add FiEdit2, FiImage, and FiTrash2 to the import

const ProfilePage = () => {
    const [profile, setProfile] = useState(null); // Initialize profile state
    const [posts, setPosts] = useState([]); // Keep this state for actual posts
    const [newPost, setNewPost] = useState({ content: '', media: [] }); // Keep this for new post creation
    const [showNewPostForm, setShowNewPostForm] = useState(false); // Keep this for showing/hiding the new post form
    const { logout } = useAuth(); // Use logout from AuthProvider
    const navigate = useNavigate(); // Initialize navigate
    const { userId } = useParams(); // Get userId from URL parameters
    const [username, setUsername] = useState(''); // State for username
    const [error, setError] = useState(''); // State for error messages
    const [followersCount, setFollowersCount] = useState(0); // State for followers count
    const [followingCount, setFollowingCount] = useState(0); // State for following count
    const base = process.env.REACT_APP_API_BASE_URL;
    const local = process.env.REACT_APP_LOCAL_URL;

    // Default profile picture and cover image URLs
    const defaultProfilePicture = "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o=";
    const defaultCoverImage = "https://43bb9d1c7f95c24a2aa8-58ec507a5ae9dad69c8938b724dd8981.ssl.cf1.rackcdn.com/CrazyCap_trainerset01BLACKPR.jpg"; // You can replace this with any default cover image URL

    const [showDropdown, setShowDropdown] = useState(false);

    const [mainProfilePicture, setMainProfilePicture] = useState(defaultProfilePicture);
    const [postProfilePicture, setPostProfilePicture] = useState(defaultProfilePicture);

    const handleSettingsClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleLogout = () => {
        // Implement logout functionality here
        console.log('Logging out...');
    };

    // Update the fetchProfileAndPosts function
    const fetchProfileAndPosts = async () => {
        try {
            console.log('Fetching profile for userId:', userId);
            const profileResponse = await axios.get(`${base}/api/profile/${userId}`);
            setProfile(profileResponse.data);
            setUsername(profileResponse.data.username);
            
            // Fetch profile picture separately
            const profilePictureResponse = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
            if (profilePictureResponse.data.signedUrl) {
                console.log('Setting profile picture to signed URL:', profilePictureResponse.data.signedUrl);
                setMainProfilePicture(profilePictureResponse.data.signedUrl);
                setPostProfilePicture(profilePictureResponse.data.signedUrl);
            } else {
                console.log('No profile picture URL, using default');
                setMainProfilePicture(defaultProfilePicture);
                setPostProfilePicture(defaultProfilePicture);
            }

            // Fetch actual posts
            const postsResponse = await axios.get(`${base}/api/profile/posts/${userId}`);
            setPosts(postsResponse.data);

            // Set followers and following counts (replace with actual data when available)
            setFollowersCount(1234);
            setFollowingCount(567);
        } catch (error) {
            console.error('Error fetching profile and posts:', error);
            setMainProfilePicture(defaultProfilePicture);
            setPostProfilePicture(defaultProfilePicture);
        }
    };

    useEffect(() => {
        fetchProfileAndPosts();
    }, [userId, base]);

    const handleNewPostChange = (e) => {
        setNewPost({ ...newPost, content: e.target.value });
    };

    const handleFileChange = (e) => {
        setNewPost({ ...newPost, media: [...e.target.files] });
    };

    // Update the handlePostSubmit function
    const handlePostSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('content', newPost.content);
        newPost.media.forEach((file) => formData.append('media', file));

        try {
            const response = await axios.post(`${base}/api/profile/create-post`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            // Refresh posts after successful creation
            fetchProfileAndPosts();
            setNewPost({ content: '', media: [] });
            setShowNewPostForm(false);
        } catch (error) {
            console.error('Error creating post:', error);
            setError('Failed to create post');
        }
    };

    const handleProfilePictureChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // Check file size (5MB limit)
            if (file.size > 5 * 1024 * 1024) {
                setError('File size exceeds 5MB limit');
                return;
            }

            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Data = reader.result.split(',')[1];
                const fileType = file.type;
                const fileName = file.name;

                try {
                    const response = await axios.post(`${base}/api/profile/upload-profile-picture`, {
                        userId: userId,
                        image: {
                            filename: fileName,
                            content: base64Data,
                            contentType: fileType
                        }
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (response.data.signedUrl) {
                        setProfile(prevProfile => ({
                            ...prevProfile,
                            profile_picture_url: response.data.url
                        }));
                        setMainProfilePicture(response.data.signedUrl);
                        setPostProfilePicture(response.data.signedUrl);
                    } else {
                        setError('Failed to upload profile picture: No signed URL received');
                    }
                } catch (error) {
                    console.error('Error uploading profile picture:', error);
                    setError('Failed to upload profile picture: ' + (error.response?.data?.message || error.message));
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const fetchProfilePicture = async () => {
            try {
                const response = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
                if (response.data.signedUrl) {
                    setMainProfilePicture(response.data.signedUrl);
                    setPostProfilePicture(response.data.signedUrl);
                } else {
                    setMainProfilePicture(defaultProfilePicture);
                    setPostProfilePicture(defaultProfilePicture);
                }
            } catch (error) {
                console.error('Error fetching profile picture:', error);
                setMainProfilePicture(defaultProfilePicture);
                setPostProfilePicture(defaultProfilePicture);
            }
        };

        fetchProfilePicture();
    }, [userId, base]);

    useEffect(() => {
        const refreshSignedUrl = async () => {
            if (profile && profile.profile_picture_url) {
                try {
                    const signedUrlResponse = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
                    if (signedUrlResponse.data.signedUrl) {
                        setMainProfilePicture(signedUrlResponse.data.signedUrl);
                        setPostProfilePicture(signedUrlResponse.data.signedUrl);
                    }
                } catch (error) {
                    console.error('Error refreshing signed URL:', error);
                }
            }
        };

        // Refresh the signed URL every 50 minutes (3000000 milliseconds)
        const intervalId = setInterval(refreshSignedUrl, 3000000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [profile, userId, base]);

    const handleDeletePost = async (postId) => {
        if (window.confirm('Are you sure you want to delete this post?')) {
            try {
                await axios.delete(`${base}/api/profile/delete-post/${postId}`, {
                    data: { userId: userId }
                });
                // Refresh posts after successful deletion
                fetchProfileAndPosts();
            } catch (error) {
                console.error('Error deleting post:', error);
                setError('Failed to delete post');
            }
        }
    };

    if (!profile) {
        return <div>Loading...</div>; // Show loading state while fetching profile
    }

    return (
        <div className="profile-page">
            <div className="cover-image">
                <img src={defaultCoverImage} alt="Cover" />
                <div className="top-icons-container">
                    <TopIcons />
                </div>
            </div>
            <div className="profile-header">
                <div className="profile-picture">
                    <img 
                        src={mainProfilePicture} 
                        alt="Profile" 
                        crossOrigin="anonymous"
                        key={mainProfilePicture}
                        onError={(e) => {
                            console.error('Error loading image:', e.target.src);
                            e.target.src = defaultProfilePicture;
                        }}
                    />
                    <input
                        type="file"
                        id="profile-picture-input"
                        accept="image/*"
                        onChange={handleProfilePictureChange}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="profile-picture-input" className="change-picture-button">
                        <FiEdit2 />
                    </label>
                </div>
                <div className="settings-container">
                    <button className="settings-button" onClick={handleSettingsClick}>
                        <FiSettings />
                    </button>
                    {showDropdown && (
                        <div className="settings-dropdown">
                            <button onClick={() => console.log('Settings clicked')}>Settings</button>
                            <button onClick={handleLogout}>Logout</button>
                        </div>
                    )}
                </div>
                <h1 className="name">John Doe</h1>
                <h2 className="username">@{profile.username}</h2>
                <div className="follow-stats">
                    <span>{followersCount} followers</span>
                    <span> · </span>
                    <span>{followingCount} following</span>
                </div>
                <button className="new-post-button" onClick={() => setShowNewPostForm(true)}>New Post + </button>
            </div>

            <div className="user-posts">
                <div className="posts-grid">
                    {posts.map(post => (
                        <div key={post.id} className="post-card">
                            {post.media && post.media.length > 0 && (
                                post.media[0].media_type === 'image' ? (
                                    <img 
                                        src={post.media[0].signedUrl} 
                                        alt={`Post ${post.id}`} 
                                        className="post-image"
                                    />
                                ) : (
                                    <video 
                                        src={post.media[0].signedUrl} 
                                        controls 
                                        className="post-image"
                                    />
                                )
                            )}
                            <div className="post-overlay">
                                {post.content && (
                                    <p className="post-caption">{post.content}</p>
                                )}
                                <div className="post-info">
                                    <img 
                                        src={postProfilePicture} 
                                        alt="Profile" 
                                        className="post-profile-pic"
                                    />
                                    <span className="post-username">@{profile.username}</span>
                                    <button 
                                        className="delete-post-button" 
                                        onClick={() => handleDeletePost(post.id)}
                                    >
                                        <FiTrash2 />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showNewPostForm && (
                <div className="new-post-overlay">
                    <div className="new-post-form">
                        <h2>Create a New Post</h2>
                        <form onSubmit={handlePostSubmit}>
                            <textarea
                                name="content"
                                value={newPost.content}
                                onChange={handleNewPostChange}
                                placeholder="Write your post..."
                            />
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <FiImage /> Add Media
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                accept="image/*,video/*"
                                style={{ display: 'none' }}
                            />
                            {newPost.media.length > 0 && (
                                <p>{newPost.media.length} file(s) selected</p>
                            )}
                            <div className="form-buttons">
                                <button type="submit" className="post-button">Post</button>
                                <button type="button" className="cancel-button" onClick={() => setShowNewPostForm(false)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfilePage;